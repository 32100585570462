import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';


const fbConfig = {
  apiKey: "AIzaSyCnH3DJrCfM802ZwowuX-VuV5RD_Z47HvI",
  authDomain: "jakewiesenthal.firebaseapp.com",
  projectId: "jakewiesenthal",
  storageBucket: "jakewiesenthal.appspot.com",
  messagingSenderId: "843115260813",
  appId: "1:843115260813:web:0562ebcf47ab2421482bc4",
  measurementId: "G-3N31SGTJ36"
};
firebase.initializeApp(fbConfig);
firebase.firestore().settings({ timestampsInSnapshots: true });

export default fbConfig 